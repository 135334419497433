* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Century Gothic", CenturyGothic, Geneva, AppleGothic, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: #f2f2f2;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  background-color: #f2f2f2;
}

.content-container {
  margin-left: 15%;
  margin-right: 15%;
}

/* Nav */

.nav-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: fixed;
  z-index: 2;
  top: 0;
  height: 7%;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.nav-button {
  display: flex;
  width: 10%;
  justify-content: center;
  opacity: 0.5;
  cursor: pointer;
}

.nav-button:hover{
  opacity: 1;
}

/* Welcome */
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

.welcome-container {
  line-height: 1.5;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    white 0%,
    white 50%,
    #f2f2f2 50%,
    #f2f2f2 100%
  );
}

.welcome-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  margin-top: 3rem;
}

.welcome-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.75;
}

.welcome-intro-hidden {
  visibility: hidden;
  font-size: 2rem;
  font-weight: lighter;
}

.welcome-intro {
  font-size: 2rem;
  font-weight: lighter;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.welcome-description-hidden {
  visibility: hidden;
  font-size: 2rem;
}

.welcome-description {
  font-size: 2rem;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.profile-picture {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  animation-name: fadeIn;
  animation-duration: 1s;
  border: solid 5px white;
  filter: brightness(95%);
}

.welcome-scroll-hidden {
  visibility: hidden;
  margin-top: 4rem;
}

.welcome-scroll {
  margin-top: 4rem;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  opacity: 0.75;
}

/* About */

.about-container {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0.1rem 0.1rem #e8e7e4;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.about-header {
  height: 2rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.33);
  width: 100%;
  text-align: center;
}

.about-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.icon {
  height: 75px;
  width: 75px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #e8e7e4;
}

.icon:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1100px) {
  .content-container {
    margin-left: 5%;
    margin-right: 5%;
  }
  .about-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  .about-description {
    justify-content: center;
    align-items: center;
  }
  .about-header {
    text-align: center;
  }
  .welcome-text {
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .content-container {
    margin-left: 5%;
    margin-right: 5%;
  }
  .about-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .about-description {
    justify-content: center;
    align-items: center;
  }
  .nav-container {
    justify-content: space-around;
  }
  .welcome-scroll-hidden {
    visibility: hidden;
    margin-top: 0.5rem;
  }

  .welcome-scroll {
    margin-top: 0.5rem;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    opacity: 0.75;
  }
}

@media screen and (max-width: 576px) {
  .profile-picture {
    height: 250px;
    width: 250px;
  }
}

@media screen and (min-width: 576px) {
  .content-container {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (max-width: 320px) {
  .icon-container {
    flex-direction: column;
  }
}

/* Skills */
.skills-container {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0.1rem 0.1rem #e8e7e4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.skills-header {
  height: 2rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.33);
  width: 100%;
  text-align: center;
}

.skills-icons-container {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.skills-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.skill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill-icon {
  height: 100px;
  width: 100px;
  background-color: white;
  color: white;
}

.skill-description {
  margin-top: 0.25rem;
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .skill-icon {
    height: 75px;
    width: 75px;
  }
  .skills-icons-container {
    width: 100%;
  }
}
@media screen and (max-width: 300px) {
  .skill-description {
    visibility: hidden;
  }
}

/* Projects */

.projects-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

.projects-row {
  display: flex;
  gap: 1rem;
}

.project-card {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color:#fff;
  border-radius: 10px;
  box-shadow: 0.1rem 0.1rem #e8e7e4;
}

#centered {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.project-header {
  border-bottom: solid 1px rgba(0, 0, 0, 0.33);
  height: 2rem;
  margin-bottom: 1rem;
}

.project-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;
}

.project-description {
  margin-bottom: 1rem;
  min-height: 120px;
}

.project-description-alt {
  margin-bottom: 1rem;
}

.project-preview-container {
  margin-bottom: 1rem;
}

.project-thumbnail {
  height: auto;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.25);
}
.standard-project {
  width: 100%;
  max-width: 480px;
  max-height: 270px;
}

.mobile-project {
  width: 100%;
  max-width: 200px;
  max-height: 350px;
}

.mobile-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .projects-row {
    display: block;
  }
  .project-card {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
  .project-description {
    min-height: auto;
  }
}

@media screen and (max-width: 768px) {
  .projects-row {
    display: block;
  }
  .project-card {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
}